.truncate {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
  white-space: nowrap;
  width: 100%;
}

html,
body {
  background-color: #f2f5f7;
  color: rgba(14, 30, 37, 0.54);
  overflow: auto;
}

#background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 250px;
  background-color: #00d1b259;
}

.navbar-menu.is-active {
  @media screen and (max-width: 1087px) {
    box-shadow: 0 8px 8px rgba(10, 10, 10, 0.041);
    background-color: #ffffff00;
  }
}

.button.is-primary {
  color: rgba(0, 0, 0, 0.705);
}
